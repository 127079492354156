<template>
    <div id="header">
        <div class="header">
            <div class="logo">
                <img src="@/assets/logo2.png" alt="" srcset="" @click="$router.push('/home')">
            </div>
            <div class="navTab">
                <ul class="oneUl">
                    <li class="oneLi" :class="{ 'active': activeIndex == index }" v-for="(item, index) in navList"
                        :key="index" @mouseenter="moveFun(index)" @mouseleave="moveLeve(index)">
                        <div class="a_href" @click="menuClick(item.url)">{{ item.name }}</div>
                        <div class="navChild">
                            <!-- <div class="child" v-show="activeIndex == index && item.childList.length"> -->
                                <div class="child" v-show="activeIndex == index && item.childList.length">
                                <ul class="twoUl">
                                    <li class="twoLi" v-for="(cItem, cIndex) in item.childList"
                                        @click="menuChildClick(cItem.url, index)">
                                        {{ cItem.name }}
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
            <div class="nav_search">
                <div class="searchIcon">
                    <img src="@/assets/images/phone2.png" alt="" srcset="" @click="isSearch = !isSearch">
                </div>
                <span>{{ $phone($store.state.phone) }}</span>
            </div>

        </div>
    </div>

</template>

<script>
import { getClassify } from "@/api/product";
import { mapState, mapMutations } from "vuex";
export default {
    name: 'Header',
    data() {
        return {
            activeIndex: -1,
            navList: [
                { name: '首页', url: '/home', childList: [] },
								//{ name: '幸福婚恋', url: '/love', childList: [] },
                { name: '关于爱幸福', url: '/brandStory', childList: [{ name: '品牌故事', url: '/brandStory' }, { name: '核心技术', url: '/coreTechnology' }, { name: '社会责任', url: '/responsibility' }, { name: '新闻中心', url: '/newsCenter' }, { name: '联系我们', url: '/contractUs' }] },
                { name: '爱幸福文化', url: '/brandCultrue', childList: [{ name: '象征价值', url: '/symbolicValue' }, { name: '呵护与精致', url: '/designer' }, { name: '家庭咨询', url: '/familyCounseling' }] },
                { name: '产品中心', url: '/product', childList: [{ name: '不锈钢整体橱柜', url: '/product' }, { name: '不锈钢全屋定制', url: '/product' }, { name: '不锈钢+实木定制', url: '/product' }, { name: '不锈钢+版式定制', url: '/product' }, { name: '版式全屋定制', url: '/product' }, { name: '软装装饰', url: '/product' }, { name: '装修', url: '/product' }, { name: '家电', url: '/product' }] },
								
                { name: '品牌活动', url: '/loveClass', childList: [{ name: '爱的课堂', url: '/loveClass' }, { name: '家庭日', url: '/familyDay' }, { name: '爱的分享', url: '/loveShare' }, { name: '爱力银行', url: '/loveBank' }] },
                { name: '加入爱幸福', url: '/joinIfami', childList: [] }
            ],
        }
    },
    mounted() {
        this.getProductClass()
    },
    methods: {
        ...mapMutations(['setClassifyVal']),
        getProductClass() {
            getClassify({
                type: 4,
                parentId: '',
                pageNum: 1,
                pageSize: 99
            }).then((res) => {
                if (res.code + '' === '200') {
                    let childList = []
                    for (let i = 0; i < res.data.length; i++) {
                        childList.push({
                            name: res.data[i].title,
                            url: `/product?did=${res.data[i].id}`
                        })
                    }
                    this.navList[4].childList = childList
                    this.$forceUpdate()
                }
            });
        },
        menuClick(url) {
            if (!url) return;
            this.$router.push(url)
        },
        menuChildClick(url, index) {
            if (!url) return;
            if (index == 3) {
                let classifyVal = url.split('=')[1]
                this.setClassifyVal(classifyVal)
            }
            this.$router.push(url)
        },
        moveFun(index) {
            console.log("============================")
            this.activeIndex = index;
        },
        moveLeve() {
            console.log("============================222222222222")
            this.activeIndex = -1;
        },
    }
}
</script>

<style lang="scss" scoped>
.header {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #eb6100;
    height: 80px;
    position: relative;

    .logo {
        margin-right: 110px;
        cursor: pointer;

        img {
            width: 92px;
        }
    }

    .navTab {
        width: 870px;

        .oneUl {
            display: flex;
            align-items: center;
            height: 80px;
            padding: 0;
            margin: 0;

            .oneLi {
                flex: 1;
                height: 100%;
                padding: 0;
                cursor: pointer;

                .a_href {
                    width: 100%;
                    height: 100%;
                    color: #fff;
                    font-size: 16px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }

                &.active {
                    background-color: rgba(255, 255, 255, 0.2) !important;

                    .a_href {
                        color: #fff;
                    }
                }

            }
        }
    }

    .nav_search {
        margin-left: 44px;
        display: flex;
        align-items: center;
        border-radius: 10px;
        height: 45px;
        color: #fff;
        font-size: 20px;
        font-weight: 700;

        .searchIcon {
            margin-right: 6px;

            img {
                position: relative;
                top: -2px;
                width: 16px;
            }
        }

    }
}


.navChild {
    position: absolute;
    top: 80px;
    left: 0;
    right: 0;
    background: rgba(255, 255, 255, 0.5);
    z-index: 2;
    padding-left: 575px;

    .child {
        min-height: 74px;
        animation: childHeight .5s;


        .twoUl {
            min-height: 74px;
            padding: 0;
            margin: 0;
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            font-size: 14px;

            .twoLi {
                position: relative;
                padding-left: 25px;
                padding-right: 25px;

                &:not(:last-child):after {
                    content: "";
                    position: absolute;
                    right: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    height: 10px;
                    width: 2px;
                    background: #666;
                }

                &:hover {
                    color: #eb6100;
                }

            }

        }
    }
}

@keyframes childHeight {
    0% {
        opacity: 0;
    }

    100% {
        opacity: auto;
    }
}
</style>
